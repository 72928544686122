import React from 'react';
import { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import store from '../store';
import { theme } from '../settings/theme';
import { useRouterScroll } from '../core/hooks/useRouterScroll';
import { initSentry } from '../core/sentry';
import { DefaultSeo } from 'next-seo';
import SEO from '../next-seo.config';

// Styling
import '../styles/globals.scss';
import 'react-calendar/dist/Calendar.css';

initSentry();

export interface ExtenderAppProps extends AppProps {
  err?: any;
}

const App = ({ Component, pageProps, err }: ExtenderAppProps) => {
  // Make sure pages scroll to the top after we navigate to them using `next/router`
  useRouterScroll();

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <DefaultSeo {...SEO} />
        <Component {...pageProps} err={err} />
      </ThemeProvider>
    </Provider>
  );
};

export default App;
