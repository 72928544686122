import { MoveSize } from '@prisma/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface FormState {
  // The quote form
  quote: QuoteForm;
}

export interface QuoteForm {
  // Moving from zipcode
  fromZip?: string;
  // Move size
  moveSize?: MoveSize;
  // Moving to zipcode
  toZip?: string;
  // Moving Date
  date?: string;
  // Email address
  email?: string;
  // First name
  firstName?: string;
  // Last name
  lastName?: string;
  // Phone number
  phone?: string;
}

export interface SetFieldPayload {
  // Form ID, (e.g. "quote")
  formId: keyof FormState;
  // Field ID to set (e.g. "fromZip")
  fieldId: keyof QuoteForm;
  // Field value to set
  value: any;
}

export interface SetFormPayload {
  // FormID to overwrite (e.g. "quote")
  formId: keyof FormState;
  // The value to assign to the property in selected form
  value?: QuoteForm;
}

const formSlice = createSlice({
  name: 'form',
  initialState: {
    quote: {} as QuoteForm,
  } as FormState,
  reducers: {
    setField: (state, action: PayloadAction<SetFieldPayload>) => {
      const { fieldId, value, formId } = action.payload;
      state[formId] = {
        ...state[formId],
        [fieldId]: value,
      };
    },
    setForm: (state, action: PayloadAction<SetFormPayload>) => {
      const { formId, value } = action.payload;
      state[formId] = value!;
    },
  },
});

// Reducer
export default formSlice.reducer;

export const { setField, setForm } = formSlice.actions;
