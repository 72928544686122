import {
  ActionReducerMapBuilder,
  AsyncThunk,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { QuoteForm } from '../form/form.slice';
import { NoInfer } from '@reduxjs/toolkit/src/tsHelpers';

export const post = createAsyncThunk(
  'quote/post',
  async (quoteForm: QuoteForm, thunkAPI) => {
    console.log(process.env.NEXT_PUBLIC_MOVERBIRD_API_URL);
    try {
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_MOVERBIRD_API_URL}/quote`,
        {
          method: 'POST',
          body: JSON.stringify(quoteForm),
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      if (!response.ok) {
        const error = await response.json();
        return thunkAPI.rejectWithValue({ error: error.errors });
      }
      return response.json();
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const update = createAsyncThunk(
  'quote/update',
  async (data: { id: string; quoteForm: QuoteForm }, thunkAPI) => {
    try {
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_MOVERBIRD_API_URL}/quote/${data.id}`,
        {
          method: 'PATCH',
          body: JSON.stringify(data.quoteForm),
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      if (!response.ok) {
        const error = await response.json();
        return thunkAPI.rejectWithValue({ error: error.errors });
      }
      return response.json();
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export interface QuoteState {
  readonly loading?: boolean;
  readonly data?: any;
  readonly error?: string;
}

const createBuilderCases = (
  builder: ActionReducerMapBuilder<NoInfer<QuoteState>>,
  thunk: AsyncThunk<any, any, {}>,
) => {
  builder.addCase(thunk.pending, (state) => {
    delete state.error;
    state.loading = true;
  });
  builder.addCase(thunk.fulfilled, (state, action) => {
    state.data = action.payload;
    state.loading = false;
  });
  builder.addCase(thunk.rejected, (state, action) => {
    state.error = (action.payload as string) || 'something went wrong';
    state.loading = false;
  });
};

const quoteSlice = createSlice({
  name: 'form',
  initialState: {
    loading: false,
  } as QuoteState,
  reducers: {},
  extraReducers: (builder) => {
    // POST
    createBuilderCases(builder, post);
    // UPDATE
    createBuilderCases(builder, update);
  },
});

// Reducer
export default quoteSlice.reducer;
