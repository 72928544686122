import baseStyled, { ThemedStyledInterface } from 'styled-components';

export const theme = {
  color: {
    red: '#dc3545',
    blue: '#264387',
    lightBlue: '#98c1d9',
    lighterBlue: '#f7fcfe',
    cyan: '#e0fbfc',
    white: '#FFFFFF',
    green: '#28a745',
    orange: '#ee6c4d',
    primary: '#293241',
    lightGrey: '#c6cacc',
    lightGrey2: '#e8f0f6',
    lighterGrey2: '#FBFBFB',
    transparent: 'transparent',
    gradient: {
      purple: 'linear-gradient(90deg, #7A67CA 0%, #8972CC 100%)',
      blue: 'linear-gradient(90deg, #9BAAFA 0%, #367BF5 100%)',
      turquoise: 'linear-gradient(-45deg, #55d5c0 0%, #017a91 100%)',
      orange:
        'linear-gradient(-45deg, rgba(255,169,0,1) 0%, rgba(255,200,6,1) 100%)',
    },
    overlay: {
      white: 'rgba(255, 255, 255, .5)',
      whiter: 'rgba(255, 255, 255, .8)',
      lightBlue: 'rgba(152, 193, 217, .3)',
      lightGreyOpaque: 'rgba(195, 193, 193, .75)',
    },
  } as const,

  transition: {
    primary: 'all ease-in-out 0.1s',
  },

  fontSize: {
    primary: '1rem',
    lg: '1.6rem',
    md: '1.2rem',
    sm: '0.875rem',
    xs: '0.7rem',
  } as const,

  iconSize: {
    lg: '8.5rem',
  } as const,

  elevation: {
    primary: '0px 2px 10px rgba(0, 0, 0, 0.055)',
    secondary: '0px 2px 10px rgba(0, 0, 0, 0.13)',
  } as const,

  screen: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  } as const,

  zIndex: {
    dropdown: '1000',
    sticky: '1020',
    fixed: '1030',
    backdrop: '1040',
    modal: '1050',
    popover: '1060',
    tooltip: '1070',
  } as const,

  borderRadius: {
    primary: '15px',
    rounded: '50rem',
  } as const,
} as const;

export type Theme = typeof theme;
// IMPORT THIS STYLED FUNCTION TO GET TYPE SAFETY WITH THEME PROPS
// USAGE: import { styled } from './path/to/theme/theme';
export type ThemedStyled = ThemedStyledInterface<Theme>;
export const styled = baseStyled as ThemedStyled;
