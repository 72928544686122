export default {
  title: 'Moving Cost Calculator - Long Distance Moves - Local Movers',
  description:
    'Calculate your moving cost before you book your next move. Local moves hourly rates, Long distance moves and Corporate moves, Nationwide.',
  openGraph: {
    type: 'website',
    locale: 'en_US',
    site_name: 'MoverBird.',
  },
};
